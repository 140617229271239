import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
// import {useSiteData} from '../hooks/useSiteData';
import {graphql, Link} from "gatsby";
import imgPartnerHeader from '../assets/images/header-work-with-us.png';
import Seo from "../components/seo";
import InstagramIcon from "../assets/images/icons/logo_Instagram_Glyph_Gradient.png";

export default function Partner({data: {partner}}) {
	/*	const {advertise} = useSiteData();*/

	return <Page orientation="portrait">

		<Seo remoteMetaData={partner} title={partner.titleSEO} />

		<div className="game-box">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://www.instagram.com/keenkidsglobal/" target="_blank"
							   className="ico-social-instagram-wrap">
								<img src={InstagramIcon} alt="Instagram logo"
									 className="ico-social-instagram"/>
							</a>
							<a href="https://shop.keenkids.com.au" target="_blank"
							   className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<img src={imgPartnerHeader} alt="Bannana and oranges"/>

					<Markdown>{partner.bodyText}</Markdown>

					<div className="button-box">
						<a href="/contact" className="kk-btn-rounded btn-primary3">Contact us</a>
					</div>

				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
			<Sponsor/>
		</div>
		{/* / .game-box */}
	</Page>;
}

export const query = graphql`
    query {
        partner {
            header
            bodyText
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}
        }
    }
`;
